$(document).ready(function() {
    $(window).resize(function() {
        var windowwidth = $(window).width();
        console.log(windowwidth)
        $('.case').each(function() {
            if (windowwidth <= 639) {
                $('.case').removeClass('slick')
                $(this).addClass('slick');
            } else {
                $(this).removeClass('slick')
            }
        });
        $('.slick').slick({
            arrows: true,
            centerMode: true,
            centerPadding: '60px',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: true,
        });



    });
    $('.showmenu').on('click', function(e) {
        e.preventDefault();
        $('body').toggleClass('navshow')

    })

});